define("ember-cli-meta-tags/services/head-tags", ["exports", "@babel/runtime/helpers/esm/typeof"], function (_exports, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //TODO: consider polyfilled Set
  var VALID_HEAD_TAGS = Ember.A(['base', 'link', 'meta', 'script', 'noscript', 'title']);

  var _default = Ember.Service.extend({
    headData: Ember.inject.service(),
    // crawl up the active route stack and collect head tags
    collectHeadTags: function collectHeadTags() {
      var _this = this;

      var tags = {};
      var currentHandlerInfos;

      if (true) {
        currentHandlerInfos = this.get('router.targetState.routerJsState.routeInfos');
      } else {
        currentHandlerInfos = this.get('router._routerMicrolib.currentHandlerInfos');

        if (!currentHandlerInfos) {
          currentHandlerInfos = this.get('router.router.currentHandlerInfos');
        }
      }

      var handlerInfos = Ember.A(currentHandlerInfos);
      handlerInfos.forEach(function (handlerInfo) {
        if (true) {
          Ember.assign(tags, _this._extractHeadTagsFromRoute(handlerInfo.route));
        } else {
          Ember.assign(tags, _this._extractHeadTagsFromRoute(handlerInfo.handler));
        }
      });
      var tagArray = Ember.A(Object.keys(tags)).map(function (id) {
        return tags[id];
      });
      this.set('headData.headTags', Ember.A(tagArray));
    },
    _extractHeadTagsFromRoute: function _extractHeadTagsFromRoute(route) {
      var headTags = Ember.get(route, 'headTags');

      if (!headTags) {
        return {};
      }

      if (typeof headTags === 'function') {
        headTags = headTags.apply(route);
      } else if ((0, _typeof2.default)(headTags) !== 'object') {
        // not recognized construct
        return {};
      } // convert headTags to object


      return this._buildTags(headTags);
    },
    // ensure all tags have a tagId and build object keyed by id
    _buildTags: function _buildTags(headTagsArray) {
      var tagMap = {};
      Ember.A(headTagsArray).forEach(function (tagDefinition) {
        if (!VALID_HEAD_TAGS.includes(tagDefinition.type)) {
          return;
        }

        var tagId = tagDefinition.tagId;

        if (!tagId) {
          tagId = Ember.guidFor(tagDefinition);
        }

        tagMap[tagId] = tagDefinition;
      });
      return tagMap;
    }
  });

  _exports.default = _default;
});