define("@babel/runtime/helpers/esm/temporalRef", ["exports", "@babel/runtime/helpers/esm/temporalUndefined"], function (_exports, _temporalUndefined) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _temporalRef;

  function _temporalRef(val, name) {
    if (val === _temporalUndefined.default) {
      throw new ReferenceError(name + " is not defined - temporal dead zone");
    } else {
      return val;
    }
  }
});