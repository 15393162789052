define("ember-cli-meta-tags/initializers/router-head-tags", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    if (true) {
      Ember.Router.reopen({
        headTags: Ember.inject.service(),
        init: function init() {
          var _this = this,
              _arguments = arguments;

          this._super.apply(this, arguments);

          this.on('routeDidChange', function () {
            Ember.get(_this, 'headTags').collectHeadTags();

            _this._super.apply(_this, (0, _toConsumableArray2.default)(_arguments));
          });
        }
      });
    } else {
      Ember.Router.reopen({
        headTags: Ember.inject.service(),
        didTransition: function didTransition() {
          Ember.get(this, 'headTags').collectHeadTags();

          this._super.apply(this, arguments);
        }
      });
    }
  }

  var _default = {
    name: 'router-head-tags',
    after: 'head-tags',
    initialize: initialize
  };
  _exports.default = _default;
});