define("ember-cordova-events/utils/subscribe", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = subscribe;

  function subscribe(path, method) {
    var _listener = null;

    var _path$split = path.split('.'),
        _path$split2 = (0, _slicedToArray2.default)(_path$split, 3),
        service = _path$split2[0],
        event = _path$split2[1],
        err = _path$split2[2];

    var isValidPath = Ember.isPresent(service) && Ember.isPresent(event) && Ember.isBlank(err);
    (false && !(isValidPath) && Ember.assert("'subscribe()' expects a path with exactly one leaf, was given ".concat(path), isValidPath));

    var _computedFn = function computedFn() {
      var _this = this;

      if (Ember.isBlank(this.get(service)) || Ember.isPresent(_listener)) {
        return;
      } // ensure teardown


      var _super = this.get('willDestroy');

      this.set('willDestroy', function () {
        this.get(service).off(event, _listener);
        _listener = null;
        _computedFn = null;

        _super.call(this);
      }); // proxy the event

      _listener = function _listener(e) {
        method.call(_this, e);
      }; // subscribe to the event


      this.get(service).on(event, this, _listener);
    };

    return Ember.on.call(this, 'init', _computedFn);
  }
});