define("@ember/render-modifiers/modifiers/did-update", ["exports", "@babel/runtime/helpers/esm/classCallCheck", "@babel/runtime/helpers/esm/toArray"], function (_exports, _classCallCheck2, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember._setModifierManager(function () {
    return {
      createModifier: function createModifier() {
        return {
          element: null
        };
      },
      installModifier: function installModifier(state, element) {
        // save element into state bucket
        state.element = element;
      },
      updateModifier: function updateModifier(_ref, args) {
        var element = _ref.element;

        var _args$positional = (0, _toArray2.default)(args.positional),
            fn = _args$positional[0],
            positional = _args$positional.slice(1);

        fn(element, positional, args.named);
      },
      destroyModifier: function destroyModifier() {}
    };
  }, function DidUpdateModifier() {
    (0, _classCallCheck2.default)(this, DidUpdateModifier);
  });

  _exports.default = _default;
});