define("@ember-decorators/object/index", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/typeof", "@ember-decorators/utils/collapse-proto", "@ember-decorators/utils/decorator", "@ember-decorators/utils/computed"], function (_exports, _toConsumableArray2, _typeof2, _collapseProto, _decorator, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.off = _exports.on = _exports.unobserves = _exports.observes = _exports.wrapComputed = _exports.computed = _exports.action = void 0;
  var BINDINGS_MAP = new WeakMap();
  /**
    Decorator that turns the target function into an Action
  
    Adds an `actions` object to the target object and creates a passthrough
    function that calls the original. This means the function still exists
    on the original object, and can be used directly.
  
    ```js
    export default class ActionDemoComponent extends Component {
      @action
      foo() {
        // do something
      }
    }
    ```
  
    ```hbs
    <!-- template.hbs -->
    <button onclick={{action "foo"}}>Execute foo action</button>
    ```
  
    Also binds the function directly to the instance, so it can be used in any
    context:
  
    ```hbs
    <!-- template.hbs -->
    <button onclick={{this.foo}}>Execute foo action</button>
    ```
  
    @function
    @return {Function}
  */

  var action = (0, _decorator.decorator)(function (desc) {
    (false && !(desc && desc.kind === 'method' && desc.descriptor && typeof desc.descriptor.value === 'function') && Ember.assert('The @action decorator must be applied to methods', desc && desc.kind === 'method' && desc.descriptor && typeof desc.descriptor.value === 'function'));
    var actionFn = desc.descriptor.value;
    desc.descriptor = {
      get: function get() {
        var bindings = BINDINGS_MAP.get(this);

        if (bindings === undefined) {
          bindings = new Map();
          BINDINGS_MAP.set(this, bindings);
        }

        var fn = bindings.get(actionFn);

        if (fn === undefined) {
          fn = actionFn.bind(this);
          bindings.set(actionFn, fn);
        }

        return fn;
      }
    };

    desc.finisher = function (target) {
      var key = desc.key;
      var prototype = target.prototype;
      (0, _collapseProto.default)(prototype);

      if (!prototype.hasOwnProperty('actions')) {
        var parentActions = prototype.actions;
        prototype.actions = parentActions ? Object.create(parentActions) : {};
      }

      prototype.actions[key] = actionFn;
      return target;
    };

    return desc;
  });
  /**
    Decorator that turns a native getter/setter into a computed property. Note
    that though they use getters and setters, you must still use the Ember `get`/
    `set` functions to get and set their values.
  
    ```js
    import Component from '@ember/component';
    import { computed } from '@ember-decorators/object';
  
    export default class UserProfileComponent extends Component {
      first = 'Bruce';
      last = 'Wayne';
  
      @computed('first', 'last')
      get name() {
        return `${this.first} ${this.last}`; // => 'Bruce Wayne'
      }
  
      set name(value) {
        if (typeof value !== 'string' || !value.test(/^[a-z]+ [a-z]+$/i)) {
          throw new TypeError('Invalid name');
        }
  
        const [first, last] = value.split(' ');
        this.setProperties({ first, last });
      }
    }
    ```
  
    Can also be optionally passed a computed property descriptor (e.g. a function
    or an object with `get` and `set` functions on it):
  
    ```js
    let fullNameComputed = computed('firstName', 'lastName', {
      get() {
        return `${this.first} ${this.last}`; // => 'Diana Prince'
      },
  
      set(key, value) {
        if (typeof value !== 'string' || !value.test(/^[a-z]+ [a-z]+$/i)) {
          throw new TypeError('Invalid name');
        }
  
        const [first, last] = value.split(' ');
        this.setProperties({ first, last });
  
        return value;
      }
    })
  
    export default class UserProfileComponent extends Component {
      first = 'Diana';
      last = 'Prince';
  
      @fullNameComputed fullName;
    }
    ```
  
    @function
    @param {...string} propertyNames - List of property keys this computed is dependent on
    @param {ComputedPropertyDesc?} desc - Optional computed property getter/setter
    @return {ComputedDecorator}
  */

  _exports.action = action;
  var computed;
  _exports.computed = computed;

  if (false) {
    _exports.computed = computed = (0, _computed.computedDecoratorWithParams)(Ember.computed);
  } else {
    _exports.computed = computed = (0, _computed.computedDecoratorWithParams)(function (_ref2) {
      var key = _ref2.key,
          descriptor = _ref2.descriptor,
          initializer = _ref2.initializer;
      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      (false && !(!(descriptor && typeof descriptor.value === 'function')) && Ember.assert("@computed can only be used on accessors or fields, attempted to use it with ".concat(key, " but that was a method. Try converting it to a getter (e.g. `get ").concat(key, "() {}`)"), !(descriptor && typeof descriptor.value === 'function')));
      (false && !(!initializer) && Ember.assert("@computed can only be used on empty fields. ".concat(key, " has an initial value (e.g. `").concat(key, " = someValue`)"), !initializer));
      var lastArg = params[params.length - 1];
      var get, set;
      (false && !(!((typeof lastArg === 'function' || (0, _typeof2.default)(lastArg) === 'object') && lastArg instanceof Ember.ComputedProperty)) && Ember.assert("computed properties should not be passed to @computed directly, use wrapComputed for the value passed to ".concat(key, " instead"), !((typeof lastArg === 'function' || (0, _typeof2.default)(lastArg) === 'object') && lastArg instanceof Ember.ComputedProperty)));

      if (typeof lastArg === 'function') {
        params.pop();
        get = lastArg;
      }

      if ((0, _typeof2.default)(lastArg) === 'object' && lastArg !== null) {
        params.pop();
        get = lastArg.get;
        set = lastArg.set;
      }

      (false && !(!(descriptor && (typeof get === 'function' || typeof 'set' === 'function') && (typeof descriptor.get === 'function' || typeof descriptor.get === 'function'))) && Ember.assert("Attempted to apply a computed property that already has a getter/setter to a ".concat(key, ", but it is a method or an accessor. If you passed @computed a function or getter/setter (e.g. `@computed({ get() { ... } })`), then it must be applied to a field"), !(descriptor && (typeof get === 'function' || typeof 'set' === 'function') && (typeof descriptor.get === 'function' || typeof descriptor.get === 'function'))));
      var usedClassDescriptor = false;

      if (get === undefined && set === undefined) {
        usedClassDescriptor = true;
        get = descriptor.get;
        set = descriptor.set;
      }

      (false && !(typeof get === 'function' || typeof 'set' === 'function') && Ember.assert("Attempted to use @computed on ".concat(key, ", but it did not have a getter or a setter. You must either pass a get a function or getter/setter to @computed directly (e.g. `@computed({ get() { ... } })`) or apply @computed directly to a getter/setter"), typeof get === 'function' || typeof 'set' === 'function'));

      if (descriptor !== undefined) {
        // Unset the getter and setter so the descriptor just has a plain value
        descriptor.get = undefined;
        descriptor.set = undefined;
      }

      var setter = set;

      if (usedClassDescriptor === true && typeof set === 'function') {
        // Because the setter was defined using class syntax, it cannot have the
        // same `set(key, value)` signature, and it may not return a value. We
        // convert the call internally to pass the value as the first parameter,
        // and check to see if the return value is undefined and if so call the
        // getter again to get the value explicitly.
        setter = function setter(key, value) {
          var ret = set.call(this, value);
          return typeof ret === 'undefined' ? get.call(this) : ret;
        };
      }

      return Ember.computed.apply(void 0, (0, _toConsumableArray2.default)(params).concat([{
        get: get,
        set: setter
      }]));
    });
  }
  /**
    Wraps an instance of a ComputedProperty, turning it into a decorator:
  
    ```js
    import Component from '@ember/component';
    import { computed } from '@ember/object';
    import { wrapComputed } from '@ember-decorators/object';
  
    export default class UserProfileComponent extends Component {
      first = 'Bruce';
      last = 'Wayne';
  
      @wrapComputed(
        computed('first', 'last', function() {
          return `${this.first} ${this.last}`; // => 'Bruce Wayne'
        })
      ) fullName;
    }
    ```
  
    @function
    @param {ComputedProperty} cp - an instance of a computed property
    @return {ComputedDecorator}
  */


  var wrapComputed;
  _exports.wrapComputed = wrapComputed;

  if (false) {
    _exports.wrapComputed = wrapComputed = (0, _computed.computedDecoratorWithParams)(function () {
      for (var _len = arguments.length, params = new Array(_len), _key = 0; _key < _len; _key++) {
        params[_key] = arguments[_key];
      }

      (false && !(params.length === 1) && Ember.assert("wrapComputed should receive exactly one parameter, a ComputedProperty. Received ".concat(params), params.length === 1));
      (false && !(typeof params[0] === 'function') && Ember.assert("wrapComputed should receive an instance of a ComputedProperty. Received ".concat(params[0]), typeof params[0] === 'function'));
      (false && !(!params[0].__isComputedDecorator) && Ember.assert("wrapComputed received a ComputedDecorator. Because the value is already a decorator, there is no need to wrap it.", !params[0].__isComputedDecorator));
      return params[0];
    });
  } else {
    _exports.wrapComputed = wrapComputed = (0, _computed.computedDecoratorWithParams)(function (desc, params) {
      (false && !(params.length === 1) && Ember.assert("wrapComputed should receive exactly one parameter, a ComputedProperty. Received ".concat(params, " for ").concat(desc.key), params.length === 1));
      (false && !(false ? typeof params[0] === 'function' : params[0] instanceof Ember.ComputedProperty) && Ember.assert("wrapComputed should receive an instance of a ComputedProperty. Received ".concat(params, " for ").concat(desc.key), false ? typeof params[0] === 'function' : params[0] instanceof Ember.ComputedProperty));
      (false && !(!params[0].__isComputedDecorator) && Ember.assert("wrapComputed received a ComputedDecorator for ".concat(desc.key, ". Because the value is already a decorator, there is no need to wrap it."), !params[0].__isComputedDecorator));
      return params[0];
    });
  }

  var hasChainsFinished = false;
  var CHAINS_FINISHED = new WeakMap();
  /**
    Triggers the target function when the dependent properties have changed
  
    ```javascript
    import { observes } from '@ember-decorators/object';
  
    class Foo {
      @observes('foo')
      bar() {
        //...
      }
    }
    ```
  
    @function
    @param {...String} propertyNames - Names of the properties that trigger the function
   */

  var observes = (0, _decorator.decoratorWithRequiredParams)(function (desc, params) {
    (false && !(desc && desc.descriptor && typeof desc.descriptor.value === 'function') && Ember.assert('The @observes decorator must be applied to functions', desc && desc.descriptor && typeof desc.descriptor.value === 'function')); // hasChainsFinished will be set to true when the first initializer field
    // is added during class evaluation, then back to false when the finishers
    // run. This is not ideal, but shouldn't be an issue in the future when we
    // have the ability to add arbitrary initializers that don't have to be fields

    if (hasChainsFinished === false) {
      hasChainsFinished = true; // hackity hackity haaaaaack

      desc.extras = [{
        kind: 'field',
        placement: 'own',
        key: '__EMBER_DECORATORS_FINISH_CHAINS__',
        descriptor: {
          enumerable: false,
          writable: true,
          configurable: true
        },
        initializer: function initializer() {
          if (!(this instanceof Ember.Object) && !CHAINS_FINISHED.has(this)) {
            Ember.finishChains(Ember.meta(this));
            CHAINS_FINISHED.set(this, true);
          }
        }
      }];
    }

    desc.finisher = function (target) {
      hasChainsFinished = false;
      var prototype = target.prototype;

      if (false
      /* NEEDS_STAGE_1_DECORATORS */
      ) {
        (false && !(prototype instanceof Ember.Object) && Ember.assert("You attempted to use @observes on ".concat(target.name, "#").concat(desc.key, ", which does not extend from EmberObject. This does not work with stage 1 decorator transforms, and will break in subtle ways. You must either update to the stage 2 transforms (@ember-decorators/babel-transforms v3.1+) or rewrite your class to extend from EmberObject."), prototype instanceof Ember.Object));
      }

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = params[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var path = _step.value;
          Ember.expandProperties(path, function (expandedPath) {
            Ember.addObserver(prototype, expandedPath, null, desc.key);
          });
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return target;
    };

    return desc;
  }, 'observes');
  /**
    Removes observers from the target function.
  
    ```javascript
    import { observes, unobserves } from '@ember-decorators/object';
  
    class Foo {
      @observes('foo')
      bar() {
        //...
      }
    }
  
    class Bar extends Foo {
      @unobserves('foo') bar;
    }
    ```
  
    @function
    @param {...String} propertyNames - Names of the properties that no longer trigger the function
   */

  _exports.observes = observes;
  var unobserves = (0, _decorator.decoratorWithRequiredParams)(function (desc, params) {
    desc.finisher = function (target) {
      var prototype = target.prototype;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = params[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var path = _step2.value;
          Ember.expandProperties(path, function (expandedPath) {
            Ember.removeObserver(prototype, expandedPath, null, desc.key);
          });
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      return target;
    };

    return desc;
  }, 'unobserves');
  /**
    Adds an event listener to the target function.
  
    ```javascript
    import { on } from '@ember-decorators/object';
  
    class Foo {
      @on('fooEvent', 'barEvent')
      bar() {
        //...
      }
    }
    ```
  
    @function
    @param {...String} eventNames - Names of the events that trigger the function
   */

  _exports.unobserves = unobserves;
  var on = (0, _decorator.decoratorWithRequiredParams)(function (desc, params) {
    (false && !(desc && desc.descriptor && typeof desc.descriptor.value === 'function') && Ember.assert('The @on decorator must be applied to functions', desc && desc.descriptor && typeof desc.descriptor.value === 'function'));

    desc.finisher = function (target) {
      var prototype = target.prototype;
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = params[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var eventName = _step3.value;
          Ember.addListener(prototype, eventName, null, desc.key);
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }

      return target;
    };

    return desc;
  }, 'on');
  /**
    Removes an event listener from the target function.
  
    ```javascript
    import { on, off } from '@ember-decorators/object';
  
    class Foo {
      @on('fooEvent', 'barEvent')
      bar() {
        //...
      }
    }
  
    class Bar extends Foo {
      @off('fooEvent', 'barEvent') bar;
    }
    ```
  
    @function
    @param {...String} eventNames - Names of the events that no longer trigger the function
   */

  _exports.on = on;
  var off = (0, _decorator.decoratorWithRequiredParams)(function (desc, params) {
    desc.finisher = function (target) {
      var prototype = target.prototype;
      var _iteratorNormalCompletion4 = true;
      var _didIteratorError4 = false;
      var _iteratorError4 = undefined;

      try {
        for (var _iterator4 = params[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
          var eventName = _step4.value;
          Ember.removeListener(prototype, eventName, null, desc.key);
        }
      } catch (err) {
        _didIteratorError4 = true;
        _iteratorError4 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion4 && _iterator4.return != null) {
            _iterator4.return();
          }
        } finally {
          if (_didIteratorError4) {
            throw _iteratorError4;
          }
        }
      }

      return target;
    };

    return desc;
  }, 'off');
  _exports.off = off;
});