define("ember-one-way-select/templates/components/one-way-select/option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jLDkjFmQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"option\"],[12,\"value\",[27,\"if\",[[23,[\"optionValuePath\"]],[27,\"get\",[[23,[\"option\"]],[23,[\"optionValuePath\"]]],null],[23,[\"option\"]]],null]],[12,\"selected\",[27,\"one-way-select/contains\",[[23,[\"selected\"]],[23,[\"option\"]],[23,[\"optionValuePath\"]],[23,[\"optionTargetPath\"]]],null]],[9],[0,\"\\n\"],[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"optionComponent\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"component\",[[23,[\"optionComponent\"]]],[[\"option\",\"index\",\"groupIndex\"],[[23,[\"option\"]],[23,[\"index\"]],[23,[\"groupIndex\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"optionLabelPath\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"get\",[[23,[\"option\"]],[23,[\"optionLabelPath\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"optionValuePath\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"get\",[[23,[\"option\"]],[23,[\"optionValuePath\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"optionTargetPath\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"get\",[[23,[\"option\"]],[23,[\"optionTargetPath\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[21,\"option\"],false],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-one-way-select/templates/components/one-way-select/option.hbs"
    }
  });

  _exports.default = _default;
});