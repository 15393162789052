define("@ember/render-modifiers/modifiers/did-insert", ["exports", "@babel/runtime/helpers/esm/classCallCheck", "@babel/runtime/helpers/esm/toArray"], function (_exports, _classCallCheck2, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember._setModifierManager(function () {
    return {
      createModifier: function createModifier() {},
      installModifier: function installModifier(_state, element, args) {
        var _args$positional = (0, _toArray2.default)(args.positional),
            fn = _args$positional[0],
            positional = _args$positional.slice(1);

        fn(element, positional, args.named);
      },
      updateModifier: function updateModifier() {},
      destroyModifier: function destroyModifier() {}
    };
  }, function DidInsertModifier() {
    (0, _classCallCheck2.default)(this, DidInsertModifier);
  });

  _exports.default = _default;
});