define('ember-one-way-select/components/one-way-select', ['exports', 'ember-one-way-select/templates/components/one-way-select', 'ember-one-way-select/-private/dynamic-attribute-bindings', 'ember-invoke-action'], function (exports, _oneWaySelect, _dynamicAttributeBindings, _emberInvokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OneWaySelectComponent = Ember.Component.extend(_dynamicAttributeBindings.default, {
    layout: _oneWaySelect.default,
    tagName: 'select',

    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    NON_ATTRIBUTE_BOUND_PROPS: ['value', 'update', 'options', 'paramValue', 'prompt', 'promptIsSelectable', 'includeBlank', 'optionValuePath', 'optionLabelPath', 'optionComponent', 'groupLabelPath'],

    attributeBindings: ['multiple'],

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var value = Ember.get(this, 'paramValue');
      if (value === undefined) {
        value = Ember.get(this, 'value');
      }

      Ember.set(this, 'selectedValue', value);

      var options = Ember.get(this, 'options');
      if (typeof options === 'string') {
        options = Ember.String.w(options);
      }

      var firstOption = Ember.get(Ember.A(options), 'firstObject');
      if (firstOption && Ember.isPresent(Ember.get(firstOption, 'groupName')) && Ember.isArray(Ember.get(firstOption, 'options'))) {
        Ember.set(this, 'optionsArePreGrouped', true);
      }

      if (Ember.isBlank(Ember.get(this, 'promptIsSelectable'))) {
        Ember.set(this, 'promptIsSelectable', false);
      }

      Ember.set(this, 'options', Ember.A(options));
    },


    nothingSelected: Ember.computed.empty('selectedValue'),
    promptIsDisabled: Ember.computed.not('promptIsSelectable'),
    hasGrouping: Ember.computed.or('optionsArePreGrouped', 'groupLabelPath'),
    computedOptionValuePath: Ember.computed.or('optionValuePath', 'optionTargetPath'),

    optionGroups: Ember.computed('options.[]', function () {
      var groupLabelPath = Ember.get(this, 'groupLabelPath');
      var options = Ember.get(this, 'options');
      var groups = Ember.A();

      if (!groupLabelPath) {
        return options;
      }

      options.forEach(function (item) {
        var label = Ember.get(item, groupLabelPath);

        if (label) {
          var group = groups.findBy('groupName', label);

          if (group == null) {
            group = Ember.Object.create({
              groupName: label,
              options: Ember.A()
            });

            groups.pushObject(group);
          }

          Ember.get(group, 'options').pushObject(item);
        } else {
          groups.pushObject(item);
        }
      });

      return groups;
    }),

    change: function change() {
      var value = void 0;

      if (Ember.get(this, 'multiple') === true) {
        value = this._selectedMultiple();
      } else {
        value = this._selectedSingle();
      }

      (0, _emberInvokeAction.invokeAction)(this, 'update', value);
    },


    prompt: Ember.computed.alias('includeBlank'),

    _selectedMultiple: function _selectedMultiple() {
      var _this = this;

      var options = this.element.options;
      var selectedValues = [];
      for (var i = 0; i < options.length; i++) {
        if (options[i].selected) {
          selectedValues.push(options[i].value);
        }
      }
      return selectedValues.map(function (selectedValue) {
        return _this._findOption(selectedValue);
      });
    },
    _selectedSingle: function _selectedSingle() {
      var selectedValue = this.element.value;
      return this._findOption(selectedValue);
    },
    _findOption: function _findOption(value) {
      var options = Ember.get(this, 'options');
      var optionValuePath = Ember.get(this, 'computedOptionValuePath');
      var optionTargetPath = Ember.get(this, 'optionTargetPath');
      var optionsArePreGrouped = Ember.get(this, 'optionsArePreGrouped');

      var findOption = function findOption(item) {
        if (optionValuePath) {
          return '' + Ember.get(item, optionValuePath) === value;
        } else {
          return '' + item === value;
        }
      };

      var foundOption = void 0;
      if (optionsArePreGrouped) {
        foundOption = options.reduce(function (found, group) {
          return found || Ember.get(group, 'options').find(findOption);
        }, undefined);
      } else {
        foundOption = options.find(findOption);
      }

      if (optionTargetPath && !Ember.isNone(foundOption)) {
        return Ember.get(foundOption, optionTargetPath);
      } else {
        return foundOption;
      }
    }
  });

  OneWaySelectComponent.reopenClass({
    positionalParams: ['paramValue']
  });

  exports.default = OneWaySelectComponent;
});