define('ember-cordova-splash/services/ember-cordova/splash', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    hide: function hide() {
      var _this = this;

      document.addEventListener('deviceready', function () {
        var navigator = window.navigator;
        if (Ember.isPresent(navigator) && Ember.isPresent(navigator.splashscreen)) {
          navigator.splashscreen.hide();
        } else {
          _this.error('hide');
        }
      });
    },
    show: function show() {
      var _this2 = this;

      document.addEventListener('deviceready', function () {
        var navigator = window.navigator;
        if (Ember.isPresent(navigator) && Ember.isPresent(navigator.splashscreen)) {
          navigator.splashscreen.show();
        } else {
          _this2.error('show');
        }
      });
    },
    error: function error(fnName) {
      console.warn('ember-cordova/splash:\n      Trying to call ' + fnName + ' but splash plugin is not accessible');
    }
  });
});