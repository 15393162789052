define("ember-cli-meta-tags/mixins/route-meta", ["exports", "@babel/runtime/helpers/esm/typeof", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _typeof2, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.metaToHeadTags = metaToHeadTags;
  _exports.default = void 0;

  // Route mixin for setting head meta tags on transition into/out of route
  // @example How to set meta tags on a route
  //   ExampleRoute = Ember.Route.extend RouteMetaMixin,
  //     meta: ->
  //       return
  //         meta_property_name1: meta_value1
  //         meta_property_name2: meta_value2
  function metaToHeadTags(meta) {
    var metaTypes = Object.keys(meta);
    return metaTypes.reduce(function (headTags, meta_type) {
      return headTags.pushObjects(Object.keys(meta[meta_type]).map(function (key) {
        var _attrs;

        return {
          tagId: "".concat(meta_type, ":").concat(key),
          type: 'meta',
          attrs: (_attrs = {}, (0, _defineProperty2.default)(_attrs, meta_type, key), (0, _defineProperty2.default)(_attrs, "content", meta[meta_type][key]), _attrs)
        };
      }));
    }, Ember.A([]));
  }

  var _default = Ember.Mixin.create({
    headTagsService: Ember.inject.service('head-tags'),
    // convert legacy meta tags to headTags
    headTags: function headTags() {
      var meta = this.get('meta');

      if (typeof meta === 'function') {
        meta = meta.apply(this);
      } else if ((0, _typeof2.default)(meta) !== 'object') {
        return undefined;
      }

      return metaToHeadTags(meta);
    },
    actions: {
      resetMeta: function resetMeta() {
        var service = this.get('headTagsService');
        Ember.run.next(service, 'collectHeadTags');
      }
    }
  });

  _exports.default = _default;
});