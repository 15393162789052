define("@ember-decorators/utils/-private/class-field-descriptor", ["exports", "@babel/runtime/helpers/esm/typeof", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _typeof2, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isStage2FieldDescriptor = isStage2FieldDescriptor;
  _exports.isFieldDescriptor = isFieldDescriptor;
  _exports.convertStage1ToStage2 = convertStage1ToStage2;
  var isStage1FieldDescriptor;

  if (false
  /* NEEDS_STAGE_1_DECORATORS */
  ) {
    isStage1FieldDescriptor = function isStage1FieldDescriptor(possibleDesc) {
      if (possibleDesc.length === 3) {
        var _possibleDesc = (0, _slicedToArray2.default)(possibleDesc, 3),
            target = _possibleDesc[0],
            key = _possibleDesc[1],
            desc = _possibleDesc[2];

        return (0, _typeof2.default)(target) === 'object' && target !== null && typeof key === 'string' && ((0, _typeof2.default)(desc) === 'object' && desc !== null && 'enumerable' in desc && 'configurable' in desc || desc === undefined) // TS compatibility
        ;
      } else if (possibleDesc.length === 1) {
        var _possibleDesc2 = (0, _slicedToArray2.default)(possibleDesc, 1),
            _target = _possibleDesc2[0];

        return typeof _target === 'function' && 'prototype' in _target && !_target.__isComputedDecorator;
      }

      return false;
    };
  }

  function isStage2FieldDescriptor(possibleDesc) {
    return possibleDesc && possibleDesc.toString() === '[object Descriptor]';
  }

  function isFieldDescriptor(possibleDesc) {
    var isDescriptor = isStage2FieldDescriptor(possibleDesc);

    if (false
    /* NEEDS_STAGE_1_DECORATORS */
    ) {
      isDescriptor = isDescriptor || isStage1FieldDescriptor(possibleDesc);
    }

    return isDescriptor;
  }

  function kindForDesc(desc) {
    if ('value' in desc && desc.enumerable === true) {
      return 'field';
    } else {
      return 'method';
    }
  }

  function placementForKind(kind) {
    return kind === 'method' ? 'prototype' : 'own';
  }

  function convertStage1ToStage2(desc) {
    if (desc.length === 3) {
      // Class element decorator
      var _desc = (0, _slicedToArray2.default)(desc, 3),
          key = _desc[1],
          descriptor = _desc[2];

      var kind = kindForDesc(desc);
      var placement = placementForKind(kind);
      var initializer = descriptor !== undefined ? descriptor.initializer : undefined;
      return {
        descriptor: descriptor,
        key: key,
        kind: kind,
        placement: placement,
        initializer: initializer,
        toString: function toString() {
          return '[object Descriptor]';
        }
      };
    } else {
      // Class decorator
      return {
        kind: 'class',
        elements: []
      };
    }
  }
});