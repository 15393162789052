define('ember-mockdate-shim/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var originalDate = Date;
  var originalPlatformNow = Ember.get(Ember, 'run.backburner._platform.now');

  var _ref = MockDate || {
    set: function set() {},
    reset: function reset() {}
  },
      set = _ref.set,
      reset = _ref.reset;

  /*
   * The backburner.js _platform.now function must be overridden when using this
   * addon in acceptance tests that return async model hooks. Otherwise the ember
   * run loop gets itself into an infinite loop as time will never progress.
   *
   * See this backburner PR for more info:
   * https://github.com/BackburnerJS/backburner.js/pull/264
   */
  var freezeDateAt = function freezeDateAt() {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    Ember.trySet(Ember, 'run.backburner._platform.now', function () {
      return originalDate();
    });
    set(args);
  };

  var unfreezeDate = function unfreezeDate() {
    for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    Ember.trySet(Ember, 'run.backburner._platform.now', originalPlatformNow);
    reset(args);
  };

  exports.freezeDateAt = freezeDateAt;
  exports.unfreezeDate = unfreezeDate;
});