define("@ember-decorators/object/-private/util", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@ember-decorators/utils/computed"], function (_exports, _toConsumableArray2, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.legacyMacro = legacyMacro;
  _exports.legacyMacroWithMethod = legacyMacroWithMethod;

  function legacyMacro(fn) {
    if (false) {
      return (0, _computed.computedDecoratorWithRequiredParams)(fn, fn.name);
    } else {
      return (0, _computed.computedDecoratorWithRequiredParams)(function (elementDesc, params) {
        return fn.apply(void 0, (0, _toConsumableArray2.default)(params));
      }, fn.name);
    }
  }

  function getMethod(fn, elementDesc, params, required) {
    var method;

    if (elementDesc !== undefined && elementDesc.descriptor !== undefined && typeof elementDesc.descriptor.value === 'function') {
      (false && !(false) && Ember.deprecate("Ember Decorators currently supports using the ".concat(fn.name, " macro on a function directly, but this is not supported by Ember's official decorators. We'll be removing support in future versions."), false, {
        id: 'macro-function-decoration',
        until: '6.0.0'
      }));
      method = elementDesc.descriptor.value;
      elementDesc.kind = 'field';
      elementDesc.descriptor = {};
    } else {
      method = params.pop();
    }

    (false && !(!required || typeof method === 'function') && Ember.assert("The @".concat(fn.name, " decorator must be passed a method"), !required || typeof method === 'function'));
    return method;
  }

  function legacyMacroWithMethod(fn, required) {
    if (false) {
      var computedGenerator = (0, _computed.computedDecoratorWithRequiredParams)(fn, fn.name);
      return function () {
        for (var _len = arguments.length, params = new Array(_len), _key = 0; _key < _len; _key++) {
          params[_key] = arguments[_key];
        }

        var decorator = function decorator(elementDesc) {
          var method = getMethod(fn, elementDesc, params, required);
          var computed = computedGenerator.apply(void 0, params.concat([method]));
          if (decorator._readOnly) computed.readOnly();
          if (decorator._volatile) computed.volatile();
          if (decorator._property) computed.property.apply(computed, (0, _toConsumableArray2.default)(decorator._property));
          computed(elementDesc);
        };

        Ember._setComputedDecorator(decorator);

        if (false
        /* DEBUG */
        ) {
          // This is for wrapComputed to check against invalid input
          decorator.__isComputedDecorator = true;
        }

        decorator.readOnly = function () {
          this._readOnly = true;
          return this;
        };

        decorator.volatile = function () {
          this._volatile = true;
          return this;
        };

        decorator.property = function () {
          for (var _len2 = arguments.length, params = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
            params[_key2] = arguments[_key2];
          }

          this._property = params;
          return this;
        };

        return decorator;
      };
    } else {
      return (0, _computed.computedDecoratorWithRequiredParams)(function (elementDesc, params) {
        var method = getMethod(fn, elementDesc, params, required);
        return fn.apply(void 0, (0, _toConsumableArray2.default)(params).concat([method]));
      }, fn.name);
    }
  }
});